import { Dispatch, SetStateAction } from "react"

import { TSelectedPlanState } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { LegacyPlanDisclaimer } from "src/components/Account/BillingPortal/ChangePlan/LegacyPlanDisclaimer"
import {
  OrderSummary,
  OrderSummaryProps,
} from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/OrderSummary"
import { PlanSelect } from "src/components/Account/BillingPortal/ChangePlan/PlanSelect"
import { UpdateSubscriptionButton } from "src/components/Account/BillingPortal/ChangePlan/UpdateSubscriptionButton"
import {
  getCouponIdsFromEstimate,
  TPlanWithCostEstimate,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  IPaymentSource,
  TEstimateBreakdown,
} from "src/components/Account/types"
import {
  TFetchCurrentSubscriptionResponse,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchUpdateSubscriptionEstimate } from "src/data/subscription/queries/subscriptionQueries"
import {
  TInvoiceTiming,
  TPlanChangeType,
} from "src/data/subscription/types/subscriptionTypes"
import { useHomeTokens } from "src/hooks/useHomeTokens"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"
import { Optional } from "src/utils/tsUtil"

export function ChangeSubscription({
  selectedPlan,
  setSelectedPlan,
  paymentSource,
  currencyCode,
  plansWithEstimate,
  subscription,
  discountCode,
}: {
  selectedPlan: TSelectedPlanState | undefined
  setSelectedPlan: Dispatch<SetStateAction<TSelectedPlanState | undefined>>
  paymentSource: IPaymentSource | undefined
  currencyCode: TSubscriptionCurrencyCode
  plansWithEstimate: TPlanWithCostEstimate[]
  subscription: TFetchCurrentSubscriptionResponse
  discountCode?: string
}) {
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()
  const { hasHomeTokenRole } = useHomeTokens()
  const { orgId } = useOrganization()

  const planId = selectedPlan?.planId

  /**
   * TODO WEB-589: Refactor discount code handling in change plan
   *
   * Currently the discount code is propagated down to the estimation
   * and update queries differently based on if it's a new subscription being
   * created, or if it's an existing one. We should look at aligning this.
   * We should also look at making this call a bit more stable, since the
   * discount code could fail at being applied, at which point we shouldn't
   * force reset the term, and instead show some error */
  const fetchUpdateSubscriptionEstimate = useFetchUpdateSubscriptionEstimate({
    orgId,
    body: {
      coupon_ids: discountCode ? [discountCode] : undefined,
      new_plan_id: planId ?? "no-plan",
    },
    options: { enabled: !!planId && hasHomeTokenRole },
  })
  const updateSubscriptionEstimate = fetchUpdateSubscriptionEstimate.data

  const selectedPlanWithEstimate = plansWithEstimate.find(
    (plan) => plan.availablePlan.plan_id === selectedPlan?.planId
  )

  const estimate = updateSubscriptionEstimate?.estimate

  const estimateLoading = fetchUpdateSubscriptionEstimate.isInitialLoading

  const showLegacyPlanDisclaimer =
    subscription &&
    subscription.legacy_plan &&
    subscription.subscription_status !== "cancelled"

  const planChangeType = updateSubscriptionEstimate?.plan_change_type

  const hideSummary = !selectedPlan || planChangeType === "unchanged"

  return (
    <>
      {showLegacyPlanDisclaimer && (
        <LegacyPlanDisclaimer subscription={subscription} />
      )}

      <PlanSelect
        plans={plansWithEstimate}
        currencyCode={currencyCode}
        selectedPlan={selectedPlan}
        onSelect={(plan: TSelectedPlanState) => setSelectedPlan(plan)}
        currentPlan={subscription}
      />

      <Summary
        hidden={hideSummary}
        loading={estimateLoading}
        estimate={estimate}
        breakdown={updateSubscriptionEstimate?.breakdown}
        planCost={selectedPlanWithEstimate?.cost}
        invoiceTiming={updateSubscriptionEstimate?.invoice_timing}
        planChangeType={updateSubscriptionEstimate?.plan_change_type}
        currencyCode={currencyCode}
        selectedPlan={selectedPlan}
      />

      {!paymentSource && (
        <MBanner
          type="warning"
          style={{ marginBottom: spacing.M, textAlign: "center" }}
        >
          {t(langKeys.change_plan_add_payment_method_first)}
        </MBanner>
      )}

      <UpdateSubscriptionButton
        selectedPlan={selectedPlan}
        planChangeType={updateSubscriptionEstimate?.plan_change_type}
        subscription={subscription}
        onSuccess={() => navigate(Routes.ChangePlanSuccess.location())}
        disabled={!paymentSource || estimateLoading}
        couponIds={getCouponIdsFromEstimate(estimate)}
      />
    </>
  )
}

function Loading() {
  return (
    <div>
      <MSkeleton width="30%" />
      <MSkeleton width="50%" />
      <MSkeleton width="85%" />
      <MSkeleton width="45%" />
      <MSkeleton width="100%" />
    </div>
  )
}

function Summary({
  loading,
  estimate,
  breakdown,
  planCost,
  currencyCode,
  selectedPlan,
  invoiceTiming,
  planChangeType,
  hidden,
}: {
  loading: boolean
  hidden: boolean
  breakdown: Optional<TEstimateBreakdown>
  invoiceTiming: Optional<TInvoiceTiming>
  planChangeType: Optional<TPlanChangeType>
} & Partial<OrderSummaryProps>) {
  if (loading && !hidden) {
    return <Loading />
  }

  if (!estimate || !planCost || !currencyCode || !selectedPlan || hidden) {
    return null
  }

  return (
    <OrderSummary
      estimate={estimate}
      breakdown={breakdown}
      planCost={planCost}
      selectedPlan={selectedPlan}
      currencyCode={currencyCode}
      invoiceTiming={invoiceTiming}
      planChangeType={planChangeType}
    />
  )
}
