import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"

export function ContactSalesLink() {
  const { t, langKeys } = useTranslate()

  return (
    <MText variant="bodyS">
      {t(langKeys.change_plan_contact_sales_cta)}{" "}
      <ExternalLink
        href="https://minut.com/contact/book-a-demo"
        color="unset"
        showExternalIcon={false}
      >
        {t(langKeys.contact_sales)}
      </ExternalLink>
    </MText>
  )
}
