import axios from "axios"

import { sessionStorageFactory } from "src/utils/storageUtil"
const url = `https://api.ipgeolocation.io/ipgeo?apiKey=${
  import.meta.env.REACT_APP_IP_GEOLOCATION_API_KEY
}`

interface IGeoData {
  countryCode: string
  currencyCode: string
}

const storedGeoLocation = sessionStorageFactory<IGeoData>({
  key: "minut.geoData",
})

export async function fetchGeoLocationData(): Promise<{
  countryCode: string
  currencyCode: string
}> {
  const storedGeo = storedGeoLocation.get()

  if (storedGeo) {
    return storedGeo
  }

  try {
    const { data } = await axios.get(url, { timeout: 2000 })

    const geoData = {
      countryCode: data.country_code2,
      currencyCode: data.currency.code,
    }

    storedGeoLocation.set(geoData)

    return geoData
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    return { countryCode: "US", currencyCode: "USD" }
  }
}
