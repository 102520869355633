import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import { TSelectedPlanState } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { ContactSalesLink } from "src/components/Account/BillingPortal/ChangePlan/ContactSalesLink"
import {
  centsToDollarPerMonth,
  stringToPlan,
  TPlanWithCostEstimate,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  TAvailablePlan,
  TFetchCurrentSubscriptionResponse,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { translatePlanName } from "src/data/user/logic/userPlan"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { MBadge } from "src/ui/MBadge/MBadge"
import { BodyMixin, MText } from "src/ui/MText"
import { RadioGroup } from "src/ui/RadioGroup/RadioGroup"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

export function PlanSelect({
  plans,
  currencyCode,
  selectedPlan,
  currentPlan,
  onSelect,
}: {
  plans: TPlanWithCostEstimate[]
  currencyCode: TSubscriptionCurrencyCode
  selectedPlan: TSelectedPlanState | undefined
  currentPlan?: TFetchCurrentSubscriptionResponse
  onSelect: (plan: TSelectedPlanState) => void
}) {
  const { t, langKeys } = useTranslate()

  const options = plans.map((plan) => ({
    value: {
      planId: plan.availablePlan.plan_id,
      plan: stringToPlan(plan.availablePlan.plan),
    },
    render: () => (
      <OptionBox>
        <PlanTitleRow>
          <MText variant="subtitle">
            {translatePlanName(t, plan.availablePlan.plan)}
          </MText>

          {currentPlan?.plan_id === plan.availablePlan.plan_id && (
            <div>
              <MBadge>{t(langKeys.change_plan_current_plan_pill)}</MBadge>
            </div>
          )}
        </PlanTitleRow>

        {plan.cost?.plan.unitPrice && (
          <OptionContentBox>
            <Price>
              <MText variant="subtitle" as="span">
                {" "}
                {formatPrice(
                  centsToDollarPerMonth(plan.cost.plan.unitPrice),
                  currencyCode
                )}
              </MText>
              <LowerCase>
                /{t(langKeys.month)}/{t(langKeys.home)}
              </LowerCase>
            </Price>

            <MText variant="bodyS" color="secondary">
              {t(langKeys.billed_as)}{" "}
              {formatPrice(plan.cost.plan.unitPrice / 100, currencyCode)}
              <LowerCase>/{t(langKeys.year)}</LowerCase>
            </MText>
          </OptionContentBox>
        )}

        <PlanDescription plan={plan.availablePlan.plan} />
      </OptionBox>
    ),
  }))

  return (
    <Box>
      <MText variant="subtitle">{t(langKeys.change_plan_select_title)}</MText>

      <RadioGroup<TSelectedPlanState>
        options={options}
        value={selectedPlan}
        onChange={onSelect}
        compare={(a, b) => a?.planId === b?.planId}
        hideRadio
      />

      <ContactSalesLink />
    </Box>
  )
}

function PlanDescription({ plan }: { plan: TAvailablePlan["plan"] }) {
  const { t, langKeys } = useTranslate()

  function getTranslatedDescription() {
    switch (plan) {
      case "standard":
        return t(langKeys.subscription_standard_feature_list)
      case "pro":
        return t(langKeys.subscription_pro_feature_list)
      case "pro_plus":
        return t(langKeys.subscription_pro_plus_feature_list)
      default:
        return ""
    }
  }

  return (
    <RichTextStyles>
      <MText variant="bodyS" color="secondary">
        <ReactMarkdown>{getTranslatedDescription()}</ReactMarkdown>
      </MText>
    </RichTextStyles>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const OptionBox = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const LowerCase = styled.span`
  text-transform: lowercase;
`

const Price = styled.span`
  ${BodyMixin};
`

const OptionContentBox = styled.div`
  padding-bottom: ${spacing.M};
  border-bottom: 1px solid ${mColors.divider};
`

const PlanTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const RichTextStyles = styled.div`
  ul {
    padding-left: 0;
  }

  ul li {
    margin-left: 1em; // needed to make sure that text wraps keep indentation
  }
`
