import styled from "styled-components"

import { HREF_MINUT_HELP_LEGACY_PLANS } from "src/constants/hrefs"
import { TFetchCurrentSubscriptionResponse } from "src/data/billing/types/billingTypes"
import {
  getPlanDescriptionKey,
  translatePlanName,
} from "src/data/user/logic/userPlan"
import { useTranslate } from "src/i18n/useTranslate"
import { systemWarningBackground } from "src/ui/colors"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function LegacyPlanDisclaimer({
  subscription,
}: {
  subscription: TFetchCurrentSubscriptionResponse
}) {
  const { t, langKeys } = useTranslate()

  const planDescriptionKey = getPlanDescriptionKey(subscription)

  if (!subscription.legacy_plan || !planDescriptionKey) {
    throw TypeError("This component is only meant to be used for legacy plans")
  }

  return (
    <Box>
      <MText variant="subtitle">{t(langKeys.your_plan)}</MText>

      <CurrentPlan>
        <MText variant="subtitle">
          {translatePlanName(t, subscription.plan, subscription.legacy_plan)}
        </MText>

        <MText variant="bodyS">
          {t(planDescriptionKey)}{" "}
          <ExternalLink href={HREF_MINUT_HELP_LEGACY_PLANS}>
            {t(langKeys.learn_more)}
          </ExternalLink>
        </MText>
      </CurrentPlan>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const CurrentPlan = styled.div`
  display: grid;
  gap: ${spacing.M};
  padding: ${spacing.M};
  border-radius: 8px;
  background-color: ${systemWarningBackground};
`
