import { TInvoiceEstimate } from "src/components/Account/types"
import { useTranslate } from "src/i18n/useTranslate"
import { formatPrice } from "src/utils/formatPrice"

import { LineItem } from "./LineItem"

/**
 * Displays the credits applied to the invoice. Sums up the credits coming
 * from both the refundable (which exists in the `credits_applied` property)
 * and the promotional credits (which exists in the `discounts` property).
 */
export function CreditsLineItem({
  invoiceEstimate,
}: {
  invoiceEstimate: TInvoiceEstimate
}) {
  const { t, langKeys } = useTranslate()
  const { credits_applied: creditsApplied, discounts } = invoiceEstimate

  if (!invoiceEstimate?.currency_code) {
    throw Error("No currency code in invoice estimate response")
  }

  const promoCredits = discounts?.find(
    (discount) => discount.entity_type === "promotional_credits"
  )?.amount

  const totalCredits = (creditsApplied ?? 0) + (promoCredits ?? 0)

  if (totalCredits === 0) {
    return null
  }

  const value = `-${formatPrice(
    totalCredits / 100,
    invoiceEstimate.currency_code
  )}`

  return (
    <LineItem
      description={t(langKeys.change_plan_used_credits)}
      value={value}
    />
  )
}
