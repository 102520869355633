import { useState } from "react"
import styled from "styled-components"

import { TSelectedPlanState } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { ReactivationInformation } from "src/components/Account/BillingPortal/ChangePlan/ReactivationInformation"
import { useMinutEmployee } from "src/components/Account/BillingPortal/ChangePlan/useMinutEmployee"
import { usePostSubscription } from "src/data/subscription/queries/subscriptionQueries"
import { TPostSubscriptionError400 } from "src/data/subscription/types/subscriptionTypes"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { translatePlanName } from "src/data/user/logic/userPlan"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function CreateSubscriptionButton({
  selectedPlan,
  onSuccess,
  disabled,
  numberOfHomes,
  couponIds,
}: {
  selectedPlan: TSelectedPlanState | undefined
  onSuccess: () => void
  numberOfHomes: number
  disabled?: boolean
  couponIds: string[] | undefined
}) {
  const { t, langKeys } = useTranslate()

  const minut = useMinutEmployee()
  const [couponOverride, setCouponOverride] = useState<string>("")
  const postSubscription = usePostSubscription()

  const [checkedReactivationTerms, setCheckedReactivationTerms] =
    useState(false)
  const [reactivationTermsError, setReactivationTermsError] = useState(false)

  const { isCancelled, isOwner } = useDunning()

  const isError = postSubscription.isError
  const loading = postSubscription.isLoading

  const isReactivatingHomes = isCancelled && isOwner && numberOfHomes > 0

  function createSubscription(planId: TSelectedPlanState["planId"]) {
    if (isReactivatingHomes && !checkedReactivationTerms) {
      setReactivationTermsError(true)
      return
    }

    return postSubscription.mutate(
      {
        plan_id: planId,
        coupon_ids: couponIds,
      },
      { onSuccess }
    )
  }

  if (!selectedPlan) {
    return (
      <MButton disabled fullWidth>
        {t(langKeys.change_plan)}
      </MButton>
    )
  }

  return (
    <Box>
      {isReactivatingHomes && (
        <ReactivationInformation
          checkedReactivationTerms={checkedReactivationTerms}
          onCheckedReactivationTerms={(cheked) => {
            setCheckedReactivationTerms(cheked)
            setReactivationTermsError(false)
          }}
          numberOfHomes={numberOfHomes || 0}
          reactivationTermsError={reactivationTermsError}
        />
      )}

      {isError && (
        <ErrorAlert
          errorKey={postSubscription.error?.response?.data?.error_key}
        />
      )}

      {!!minut.eligible && (
        <input
          value={couponOverride}
          onChange={(e) => setCouponOverride(e.target.value)}
          placeholder={minut.coupon}
        ></input>
      )}

      <MButton
        loading={loading}
        onClick={() => {
          createSubscription(selectedPlan.planId)
        }}
        fullWidth
        style={{ marginBottom: spacing.S }}
        disabled={disabled && !couponOverride}
      >
        {t(langKeys.change_plan_to, {
          plan_name: translatePlanName(t, selectedPlan.plan),
        })}
      </MButton>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL2};
`

function ErrorAlert({
  errorKey,
}: {
  errorKey: TPostSubscriptionError400["error_key"] | undefined
}) {
  const { t, langKeys } = useTranslate()

  // Both invalid plan and inconsistent inactive tokens are used to indicate
  // that a user has tokens of a different type from what is being purchased
  // which is currently not supported
  if (
    errorKey === "inconsistent_inactive_tokens" ||
    errorKey === "invalid_plan"
  ) {
    return (
      <MBanner type="error">
        {t(langKeys.subscription_sign_up_failed_token_mismatch)}
      </MBanner>
    )
  }

  // Show general error message
  if (
    errorKey === "home_tokens_error" ||
    errorKey === "subscription_already_exists" ||
    errorKey === "plan_not_found"
  ) {
    return (
      <MBanner type="error">{t(langKeys.failed_something_went_wrong)}</MBanner>
    )
  }

  // Show general payment error message
  return (
    <MBanner type="error">
      {`${t(langKeys.payment_failed)}. ${t(
        langKeys.payment_failed_help_funds
      )}`}
    </MBanner>
  )
}
