import { TAvailablePlan } from "src/data/billing/types/billingTypes"
import {
  TPostSubscriptionEstimateBody,
  TPostUpdateSubscriptionEstimateBody,
} from "src/data/subscription/types/subscriptionTypes"

export const subscriptionKeys = {
  all() {
    return ["subscription"] as const
  },

  subscriptionEstimate(orgId: string, filter: TPostSubscriptionEstimateBody) {
    return [...this.all(), orgId, "subscriptionEstimate", filter] as const
  },
  subscriptionUpdateEstimate(
    orgId: string,
    filter: TPostUpdateSubscriptionEstimateBody
  ) {
    return [...this.all(), orgId, "subscriptionUpdateEstimate", filter] as const
  },

  subscriptionEstimates(availablePlans?: TAvailablePlan[]) {
    return [...this.all(), "subscriptionEstimates", availablePlans] as const
  },
} as const
