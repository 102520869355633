import { useEffect } from "react"
import { useSearchParams } from "react-router-dom-v5-compat"

import { sessionStorageFactory } from "src/utils/storageUtil"

export function useGetDiscountCode(): string | undefined {
  const [searchParams] = useSearchParams()
  const storedDiscountCode = sessionStorageFactory<string>({
    key: `minut.subscription.discountCode`,
  })

  const discountCode =
    searchParams.get("discount_code") ?? storedDiscountCode.get() ?? undefined

  /**
   * Stores the discount code in session storage whenever it changes to avoid
   * setting it on every render.
   */
  useEffect(() => {
    if (discountCode === undefined) {
      return
    }

    storedDiscountCode.set(discountCode)
  }, [discountCode, storedDiscountCode])

  return discountCode
}
