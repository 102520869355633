import styled from "styled-components"

import { MText } from "src/ui/MText"

export function LineItem({
  description,
  value,
  children,
}: {
  description: React.ReactNode
  value: React.ReactNode
  children?: React.ReactNode
}) {
  return (
    <div>
      <Box>
        <MText variant="subtitle">{description}</MText>
        <MText variant="subtitle">{value}</MText>
      </Box>
      {children}
    </div>
  )
}

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`
