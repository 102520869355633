import styled from "styled-components"

import { TSelectedPlanState } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { BilledNowLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/BilledNowLineItem"
import { CreditsLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/CreditsLineItem"
import { DiscountsLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/DiscountsLineItem"
import { SubscriptionLineItem } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/SubscriptionLineItem"
import {
  formatUTCSecondsDate,
  TPlanWithCostEstimate,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  IDiscount,
  TCreditNoteEstimate,
  TDiscount,
  TEstimate,
  TEstimateBreakdown,
} from "src/components/Account/types"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { useFetchUserHomeTokens } from "src/data/homes/queries/homeTokenQueries"
import {
  TInvoiceTiming,
  TPlanChangeType,
} from "src/data/subscription/types/subscriptionTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { translatePlanName } from "src/data/user/logic/userPlan"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"
import { Optional } from "src/utils/tsUtil"

export type OrderSummaryProps = {
  planCost: TPlanWithCostEstimate["cost"]
  currencyCode: TSubscriptionCurrencyCode
  selectedPlan: TSelectedPlanState
  estimate: TEstimate
  breakdown?: Optional<TEstimateBreakdown>
  invoiceTiming?: Optional<TInvoiceTiming>
  planChangeType?: Optional<TPlanChangeType>
}

export function OrderSummary({
  planCost,
  currencyCode,
  selectedPlan,
  estimate,
  breakdown,
  invoiceTiming,
  planChangeType,
}: OrderSummaryProps) {
  const { t, langKeys } = useTranslate()
  const user = useGetUser()
  const fetchUserHomeTokens = useFetchUserHomeTokens({
    userId: user.user_id,
    filter: { activated: false },
  })

  const homeTokensCount = fetchUserHomeTokens.data?.paging.total_count || 0

  const { invoice_estimate, subscription_estimate, credit_note_estimates } =
    estimate

  const couponDiscounts =
    invoice_estimate?.discounts?.filter(couponDiscountsFilter) || []

  const nextBillingAt = subscription_estimate?.next_billing_at

  const creditAdjustment = getCreditAdjustment(credit_note_estimates)

  return (
    <Box>
      <MText variant="subtitle">{t(langKeys.order_summary)}</MText>

      <div>
        <LineItemBox>
          <SubscriptionLineItem
            planName={translatePlanName(t, selectedPlan.plan)}
            planCost={planCost}
            currencyCode={currencyCode}
            nextBillingAt={nextBillingAt}
          />

          {couponDiscounts.length > 0 && (
            <DiscountsLineItem
              discounts={couponDiscounts}
              currencyCode={currencyCode}
            />
          )}

          {invoice_estimate && (
            <CreditsLineItem invoiceEstimate={invoice_estimate} />
          )}

          <Divider />

          <BilledNowLineItem
            amountDue={invoice_estimate?.amount_due}
            currencyCode={currencyCode}
            taxes={invoice_estimate?.taxes}
            breakdown={breakdown}
          />
        </LineItemBox>
      </div>

      <BannerWrapper>
        {creditAdjustment > 0 && (
          <InfoBox title={t(langKeys.change_plan_credit_adjustment_title)}>
            {t(langKeys.change_plan_credit_adjustment_body, {
              value: formatPrice(creditAdjustment / 100, currencyCode),
            })}
          </InfoBox>
        )}

        {invoiceTiming === "end_of_term" && (
          <InfoBox
            title={t(langKeys.change_plan_time_of_change_title)}
            type="warning"
          >
            {t(langKeys.change_plan_time_of_change, {
              date: formatUTCSecondsDate(
                subscription_estimate?.next_billing_at
              ),
            })}{" "}
            {planChangeType === "downgrade" &&
              t(langKeys.home_token_convert_notice, {
                plan: translatePlanName(t, selectedPlan.plan),
              })}
          </InfoBox>
        )}

        {homeTokensCount > 0 && (
          <InfoBox
            title={t(langKeys.change_plan_subscribe_pre_paid_homes_title)}
            type="info"
          >
            {t(langKeys.change_plan_subscribe_pre_paid_homes_description, {
              quantity: homeTokensCount,
            })}
          </InfoBox>
        )}
      </BannerWrapper>
    </Box>
  )
}

function couponDiscountsFilter(discount: IDiscount | TDiscount) {
  return ["document_level_coupon", "item_level_coupon"].includes(
    discount?.entity_type ?? ""
  )
}

function getCreditAdjustment(creditNotes: TCreditNoteEstimate[] | undefined) {
  if (!creditNotes) {
    return 0
  }

  return creditNotes.reduce((prev, curr) => {
    return prev + (curr?.amount_available ?? 0)
  }, 0)
}

const LineItemBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const BannerWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
`
