import styled from "styled-components"

import { TSelectedPlanState } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { TFetchCurrentSubscriptionResponse } from "src/data/billing/types/billingTypes"
import { usePatchSubscription } from "src/data/subscription/queries/subscriptionQueries"
import { TPlanChangeType } from "src/data/subscription/types/subscriptionTypes"
import { translatePlanName } from "src/data/user/logic/userPlan"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function UpdateSubscriptionButton({
  selectedPlan,
  planChangeType,
  subscription,
  onSuccess,
  disabled,
  couponIds,
}: {
  selectedPlan: TSelectedPlanState | undefined
  planChangeType?: TPlanChangeType
  subscription: TFetchCurrentSubscriptionResponse
  onSuccess: () => void
  disabled?: boolean
  couponIds: string[] | undefined
}) {
  const { t, langKeys } = useTranslate()
  const patchSubscription = usePatchSubscription()

  function updateSubscription(planId: TSelectedPlanState["planId"]) {
    patchSubscription.mutate(
      {
        subscriptionId: subscription.subscription_id,
        body: { new_plan_id: planId, coupon_ids: couponIds },
      },
      { onSuccess }
    )
  }

  if (planChangeType === "unchanged" || !selectedPlan || !planChangeType) {
    return (
      <MButton disabled fullWidth>
        {t(langKeys.change_plan)}
      </MButton>
    )
  }

  return (
    <Box>
      {patchSubscription.isError && (
        <MBanner type="error">{`${t(langKeys.payment_failed)}. ${t(
          langKeys.payment_failed_help_funds
        )}`}</MBanner>
      )}

      <MButton
        loading={patchSubscription.isLoading}
        onClick={() => {
          updateSubscription(selectedPlan.planId)
        }}
        fullWidth
        style={{ marginBottom: spacing.S }}
        disabled={!!disabled}
      >
        {t(langKeys.change_plan_to, {
          plan_name: translatePlanName(t, selectedPlan.plan),
        })}
      </MButton>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`
