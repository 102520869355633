import styled from "styled-components"

import { HREF_MINUT_SUBSCRIPTION_TERMS } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ReactivationInformation({
  checkedReactivationTerms,
  onCheckedReactivationTerms,
  reactivationTermsError,
  numberOfHomes,
}: {
  checkedReactivationTerms: boolean
  onCheckedReactivationTerms: (checked: boolean) => void
  reactivationTermsError: boolean
  numberOfHomes: number
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Wrapper>
      <InfoBox type="info" title={t(langKeys.reactivate_plan_banner_title)}>
        {t(langKeys.reactivate_plan_banner_description)}{" "}
        <InternalLink to={Routes.Homes.location()}>
          {t(langKeys.subscription_cancelled_banner_action_secondary)}
        </InternalLink>
      </InfoBox>

      <CheckboxWrapper>
        <MBanner type="info">
          <MCheckbox
            label={
              <MText variant="bodyS">
                {t(langKeys.reactivate_plan_checkbox_label, {
                  count: numberOfHomes,
                })}{" "}
                <ExternalLink href={HREF_MINUT_SUBSCRIPTION_TERMS}>
                  {t(langKeys.learn_more)}
                </ExternalLink>
              </MText>
            }
            checked={checkedReactivationTerms}
            onCheck={onCheckedReactivationTerms}
            error={reactivationTermsError}
          />
        </MBanner>

        {reactivationTermsError && (
          <MBanner type="error">
            {t(langKeys.reactivate_plan_checkbox_error_message)}
          </MBanner>
        )}
      </CheckboxWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const CheckboxWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
`
